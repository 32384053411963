<template>
	<div class="pages-index">
		<div class="top-css1 img-pc">
			<img class="img-bg" :src="require('@/assets/image/aboutUs_top.png')" />
			<div class="c">
				<div class="c1">
					<img :src="require('@/assets/image/home_top_title.png')" />
					<div class="c1-title">通过ISO体系质量认证</div>
					<div class="c1-en">Passed ISO system quality certification</div>
					<p>
						德合信息秉持“专业、诚信、共赢”的企业文化。公司立足行业洞察，以软件研发为核心，以客户需求为导向，在移动端开发、电商系统、大型系统定制、互联网营销推广、建站及推广等业务取得重大突破并为相关行业提供专业的企业解决方案。携手德合，量身定制您专属的互联网+！
					</p>
					<div class="c1-more">下滑查看</div>
				</div>
				<div class="c2">
					<img class="img00" :src="require('@/assets/image/aboutUs_top01.png')" />
				</div>
			</div>
		</div>
		<img class="img-ph" style="width: 100%;" :src="require('@/assets/image/aboutUs_top_ph.png')" />
		<!-- ///////////////////////////////////////////////////////////// -->
		<div class="ywfw">
			<div class="img-pc" style="background-color: #090b18;height: 150px;"></div>
			<div class="img-ph" style="background-color: #090b18;height: 1700px;"></div>
			<img class="ywfw-img img-pc" :src="require('@/assets/image/aboutUs_bg.png')" />
			<img class="ywfw-img img-ph" :src="require('@/assets/image/aboutUs_bg_ph.png')" />
			<div style="position: absolute;top: 0%;width: 100%;color: #FFFFFF;">
				<div class="module-title" style="width: 100%;padding: 50px 0 50px;" id="mydiv">
					<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
					<div style="margin: 0 5px;">关于德合</div>
					<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
				</div>

				<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:20,offset:2}">
					<div class="about-css img-pc">
						<p style="text-indent: 2em;">湖南德合信息有限公司(Yunmell Tech Co.,Ltd.)，双百企业。现于长沙，是专业的“互联网+技术”服务提供商。</p>
						<p style="text-indent: 2em;">
							八年磨一剑，德合拥有30余项软件著作权，与超过60家企业达成战略合作关系，累计服务客户超过1500家。立足于行业洞察，以软件研发为核心，依托自主研发的软件产品CMS、CRM、BPM、SHOP、BI等，为企业提供更专业、更全面、更保障信息化建设服务，我们将利用创新的“互联网+技术”推动社会发展与变革，提升企业的核心竞争力，促进社会的高效运作。德合信息秉持“专业、诚信、共赢”的企业文化。公司立足行业洞察，以软件研发为核心，以客户需求为导向，在移动端开发、电商系统、大型系统定制、互联网营销推广、建站及推广等业务取得重大突破并为相关行业提供专业的企业解决方案。携手德合，量身定制您专属的互联网+！
						</p>
					</div>
					<div class="about-css img-ph">
						<p>湖南德合信息有限公司(Yunmell Tech Co.,Ltd.)，双百企业。现于长沙，是专业的“互联网+技术”服务提供商。</p>
						<p>八年磨一剑，德合拥有30余项软件著作权，与超过60家企业达成战略合作关系，累计服务客户超过1500家。</p>
						<p>立足于行业洞察，以软件研发为核心，依托自主研发的软件产品CMS、CRM、BPM、SHOP、BI等，为企业提供更专业、更全面、更保障信息化建设服务，我们将利用创新的“互联网+技术”推动社会发展与变革，提升企业的核心竞争力，促进社会的高效运作。
						</p>
						<p>德合信息秉持“专业、诚信、共赢”的企业文化。公司立足行业洞察，以软件研发为核心，以客户需求为导向，在移动端开发、电商系统、大型系统定制、互联网营销推广、建站及推广等业务取得重大突破并为相关行业提供专业的企业解决方案。携手德合，量身定制您专属的互联网+！
						</p>
					</div>
					<div style="position: relative;">
						<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:20,offset:2}"
							class="gydh-pd">
							<el-col :xs="{span:12}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}">
								<div class="kflc-css">
									<div class="col-css" style="background: #398EFF;">
										<div class="kflc-box">
											<img :src="require('@/assets/image/aboutUs_icon1.png')" />
											<div class="title">年轻</div>
											<p>这是一家85后创办的公司 团队平均年龄不超过30岁</p>
										</div>
									</div>

								</div>
							</el-col>
							<el-col :xs="{span:12}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}">
								<div class="kflc-css">
									<div class="col-css" style="background: #20C1B3;">
										<div class="kflc-box">
											<img :src="require('@/assets/image/aboutUs_icon2.png')" />
											<div class="title">专注</div>
											<p>八年专注技术开发 为企业提供软件服务</p>
										</div>
									</div>

								</div>
							</el-col>

							<el-col :xs="{span:12}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}">
								<div class="kflc-css">
									<div class="col-css" style="background: #FF8C39;">
										<div class="kflc-box">
											<img :src="require('@/assets/image/aboutUs_icon3.png')" />
											<div class="title">成熟</div>
											<p>累计服务企业1500+ 横跨多个细分行业</p>
										</div>
									</div>

								</div>
							</el-col>
							<el-col :xs="{span:12}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}">
								<div class="kflc-css">
									<div class="col-css" style="background: #FF8C39;">
										<div class="kflc-box">
											<img :src="require('@/assets/image/aboutUs_icon4.png')" />
											<div class="title">前沿</div>
											<p>持续提供新技术 在市场的应用</p>
										</div>
									</div>

								</div>
							</el-col>
							<el-col :xs="{span:12}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}">
								<div class="kflc-css">
									<div class="col-css" style="background: #398EFF;">
										<div class="kflc-box">
											<img :src="require('@/assets/image/aboutUs_icon5.png')" />
											<div class="title">全面</div>
											<p>为企业提供一站式 互联网技术服务</p>
										</div>
									</div>

								</div>
							</el-col>
							<el-col :xs="{span:12}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:8,offset:0}">
								<div class="kflc-css">
									<div class="col-css" style="background: #20C1B3;">
										<div class="kflc-box">
											<img :src="require('@/assets/image/aboutUs_icon6.png')" />
											<div class="title">专业</div>
											<p>拥有自研标准开发体系 提供全栈技术和市场实践</p>
										</div>
									</div>

								</div>
							</el-col>
						</el-col>
						<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:24,offset:0}" :lg="{span:20,offset:2}"
							class="fzlc-ph">
							<div class="module-title" style="width: 100%;padding: 50px 0 50px;">
								<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
								<div style="margin: 0 5px;">发展历程</div>
								<img style="height: 15px;" :src="require('@/assets/image/home_title_icon.png')" />
							</div>
							<div class="img-pc" style="position: relative;">
								<el-col :xs="{span:24}" :sm="{span:24}" :md="{span:20,offset:2}"
									:lg="{span:16,offset:4}" style="display: flex;justify-content: center;">
									<img class="fzlc-img" :src="require('@/assets/image/fzlc1-8/fzlc_pc.png')" />
								</el-col>


							</div>
							<div class="img-ph" style="position: relative;">
								<div class="bg-box">
									<div class="bg0"></div>
									<div class="bg1"></div>
									<div class="bg2"></div>
								</div>
								<img class="fzlc-img" :src="require('@/assets/image/fzlc1-8/fzlc_ph.png')" />
							</div>
						</el-col>
					</div>
				</el-col>
			</div>
			<div style="position: relative;width: 100%;">

			</div>
		</div>
		<!-- ///////////////////////////////////////////////////////////// -->

	</div>
</template>

<script>
	export default {
		created() {
			
		},
		async mounted() {
			if (this.$route.params) {
				this.$nextTick(() => {
					document.querySelector("#mydiv").scrollIntoView(true)
				})
			}

		},
	}
</script>

<style lang="scss" scoped>
	.img-ph {
		display: none;
	}

	.bg0 {
		border-radius: 50%;
		background: #164BA0;
		width: 28px;
		height: 28px;
	}

	.bg1 {
		width: 2px;
		height: 1250px;
		background: #164BA0;
	}

	.bg2 {
		border-radius: 50%;
		background: #161E3E;
		width: 28px;
		height: 28px;
	}

	.module-title {
		text-align: center;
		font-size: 26px;
		font-weight: bold;
		line-height: 1;
		margin-bottom: 50px;
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.top-css1 {
		color: #fff;
		position: relative;

		.img-bg {
			width: 100%;
			max-height: 800px;
		}

		.c {
			position: absolute;
			top: 35%;
			left: 5%;
			right: 5%;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
		}

		.c1 {
			width: 60%;

			img {
				width: 70%;
			}

			.c1-title {
				font-size: 50px;
			}

			.c1-en {
				font-size: 36px;
				letter-spacing: 0.065em;
			}

			.c1-more {
				border: 1px solid #FFFFFF;
				margin-top: 30px;
				border-radius: 50px;
				width: 20%;
				padding: 3px 7px;
				text-align: center;
				font-size: 24px;
				letter-spacing: 0.27em;
			}

			p {
				margin-top: 10px;
				font-size: 12px;
				letter-spacing: 0.27em;
			}
		}

		.c2 {
			width: 30%;
			margin-top: -8%;

			.img00 {
				height: 499px;
			}
		}
	}

	.about-css {
		font-size: 20px;
		margin-bottom: 100px;
		text-align: left;
		text-transform: uppercase;
	}

	.kflc-css {
		// border: 1px solid red;
		position: relative;
		color: #fff;
		width: 80%;
		height: 400px;
		margin: 30px;

		.col-css {
			height: 400px;
			width: 90%;
			padding: 0 5%;
			text-align: center;
		}

		.kflc-box {
			img {
				margin-top: 50px;
				height: 100px;
				object-fit: cover;
			}

			.title {
				font-weight: 700;
				font-size: 24px;
				margin: 30px 0;
			}

			p {
				font-size: 18px;
			}
		}
	}

	.ywfw {
		position: relative;
		text-align: center;

		.ywfw-img {
			width: 100%;
			max-height: 3000px;
			// object-fit: cover;
		}

		.bg00-img {
			width: 100%;
		}
	}

	.fzlc-img {
		height: 1250px;

	}



	@media screen and (max-width: 767px) {
		.ywfw {
			position: relative;

			.ywfw-img {
				width: 100%;
				height: 1850px;
				object-fit: cover;
			}
		}

		.fzlc-ph {
			padding: 20px;

			.img-ph {
				// border: 1px solid #0f0;
				width: 100%;

				.fzlc-img {
					position: absolute;
					top: 6%;
					left: 0%;
					width: 100%;

				}

				.bg-box {
					// border: 1px solid #fff;
					width: 18%;
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-left: auto;


					.bg0 {
						border-radius: 50%;
						background: #164BA0;
						width: 18px;
						height: 18px;
					}

					.bg1 {
						width: 2px;
						height: 1450px;
						background: #164BA0;
					}

					.bg2 {
						border-radius: 50%;
						background: #161E3E;
						width: 18px;
						height: 18px;
					}
				}



			}
		}

		.gydh-pd {
			padding: 0 0 0 20px;
		}

		.img-ph {
			display: block;
		}

		.img-pc {
			display: none;
		}

		.img-box {
			position: absolute;
			margin-top: 15%;

			.img-flex {
				display: flex;

				.img-1 {
					width: 54%;
					// background-color: #ffaaff;
					text-align: right;

					img {
						width: 100%;
						height: 100%;
						// background-color: #fff;
					}
				}

				.img-2 {
					width: 46%;
					visibility: hidden;

					img {
						width: 100%;
						height: 100%;
					}
				}

			}


		}

		.module-title {
			text-align: center;
			font-size: 26px;
			font-weight: bold;
			line-height: 1;
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin-bottom: 0px;

		}

		.top-css1 {
			color: #fff;
			position: relative;
			height: 760px;
			background-color: #080B17;



			.c {
				position: absolute;
				top: 5%;
				left: 5%;
				right: 5%;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.c2 {
				width: 100%;
				margin-top: 30px;
				text-align: center;

				.img00 {
					max-height: 360px;
				}
			}

			.c1 {
				width: 100%;

				img {
					width: 98%;
				}

				.c1-title {
					font-size: 20px;
				}

				.c1-en {
					font-size: 16px;
					letter-spacing: 0.065em;
				}

				.c1-more {
					border: 1px solid #FFFFFF;
					margin-top: 30px;
					border-radius: 50px;
					width: 30%;
					padding: 3px 7px;
					text-align: center;
					font-size: 14px;
					letter-spacing: 0.27em;
				}

				p {
					font-size: 12px;
					letter-spacing: 0.27em;
				}
			}
		}

		.about-css {
			font-size: 18px;
			margin-bottom: 0px;
			text-align: left;
			padding: 0 20px;

			p {
				line-break: anywhere;
				line-height: 174%;
				margin-bottom: 20px;
				text-transform: uppercase;
			}
		}

		.kflc-css {
			position: relative;
			color: #fff;
			width: 100%;
			height: 300px;
			margin: 0px;
			padding: 20px 0px 0px;
			// border: 1px solid #0f0;

			.col-css {
				height: 300px;
				width: 80%;
				padding: 0 5%;
				text-align: center;
			}


			.kflc-box {
				img {
					margin-top: 20px;
					height: 100px;
					object-fit: cover;
				}

				.title {
					font-weight: 700;
					font-size: 24px;
					margin: 20px 0;
				}

				p {
					font-size: 16px;
				}
			}
		}




	}
</style>
